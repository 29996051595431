import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { updatePersonalGoal, updatePersonalPage, updatePersonalPhotos, updateScreenName } from '../../../helpers/requests/fundraising-pages';
import {
  usePersonalFundraisingProgress,
  usePersonalPhoto,
  useDonationForm,
  usePersonalPageInfo,
  useParticipantAchievements,
  useScreenName,
  useBackgroundImage,
  useSurveyResponses,
} from '../../../hooks';
import ProgressBar from '../../../components/progress-bar';
import DonationPills from '../../../components/donation-pills';
import PageTitle from '../../../components/page-title';
import MobileSocialShareModal from '../../../components/mobile-social-share-modal';
import TopList from '../../../components/top-list';
import ProfileImage from '../../../components/profile-image';
import SocialShare from '../../../components/social-share';
import { convertCentsToDollars } from '../../../helpers/base';
import EditGoalModal from '../../../components/edit-goal-modal';
import EditImageModal from '../../../components/edit-image-modal';
import EditBackgroundImageModal from '../../../components/edit-background-image-modal';
import EditRichTextModal from '../../../components/edit-rich-text-modal';
import EditTitleModal from '../../../components/edit-title-modal';
import EventDetailsRow from '../../../components/event-details-row';
import { EVENT_TYPE } from '../../../constants/dom-events';
import ReactHtmlParser from 'react-html-parser';
import { updateSurveyResponses, updateSurveyBackgroundResponses } from '../../../services/luminate';
import EditEventDetailsModal from '../../../components/edit-event-details-modal';

/** page constants */
const CONS_ID = luminateProperties.personalPage.consId;
const EVENT_ID = luminateProperties.personalPage.frId;
const FORM_ID = luminateProperties.personalPage.formId;
const IS_OWN_PAGE = luminateProperties.personalPage.ownPage;
const DONATION_LINK = `${luminateProperties.secure}Donation2?df_id=${FORM_ID}&${FORM_ID}.donation=form1&PROXY_ID=${CONS_ID}&PROXY_TYPE=20&FR_ID=${EVENT_ID}`;
const TEAM_ID = luminateProperties.personalPage.teamId;
const TEAM_NAME = luminateProperties.personalPage.teamName;
const TEAM_URL = `${luminateProperties.secure}TR/DIY/Holidays?team_id=${TEAM_ID}&pg=team&fr_id=${EVENT_ID}`;
const USER_FIRST_NAME = luminateProperties.personalPage.participant.firstName;
const USER_FULL_NAME = `${luminateProperties.personalPage.participant.firstName} ${luminateProperties.personalPage.participant.lastName}`;
const DEFAULT_BG_IMAGE = '../images/content/pagebuilder/diy-registration-background-lrg.png';

document.addEventListener(EVENT_TYPE.DOM_LOAD, () => {
  const $layout = document.querySelector('#appRoot');
  ReactDOM.render(<PersonalPage />, $layout);
});

const PersonalPage = () => {
  /** hooks */
  const personalProgress = usePersonalFundraisingProgress(EVENT_ID, CONS_ID);
  const personalPhoto = usePersonalPhoto(EVENT_ID, CONS_ID);
  const participantAchievements = useParticipantAchievements(EVENT_ID, CONS_ID);
  const donationForm = useDonationForm(FORM_ID, EVENT_ID);
  const personalPageInfo = usePersonalPageInfo(EVENT_ID, CONS_ID);
  const screenName = useScreenName(EVENT_ID, CONS_ID);
  const bgImageData = useBackgroundImage(EVENT_ID);
  const surveyResponses = useSurveyResponses(EVENT_ID, CONS_ID);

  /** state */
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [editGoalModalIsOpen, setEditGoalModalIsOpen] = useState(false);
  const [editTitleModalIsOpen, setEditTitleModalIsOpen] = useState(false);
  const [editPhotoModalIsOpen, setEditPhotoModalIsOpen] = useState(false);
  const [editBackgroundPhotoModalIsOpen, setEditBackgroundPhotoModalIsOpen] = useState(false);
  const [editRichTextModalIsOpen, setEditRichTextModalIsOpen] = useState(false);
  const [editEventModalIsOpen, setEditEventModalIsOpen] = useState(false);
  const [mobileSocialShareModalIsOpen, setMobileSocialShareModalIsOpen] = useState(false);

  const [donationAmount, setDonationAmount] = useState(false);
  const [donationLevelId, setDonationLevelId] = useState('0');
  const [isOtherAmount, setIsOtherAmount] = useState(false);
  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(DEFAULT_BG_IMAGE);

  /** value initializations */
  const donationLevels = donationForm.data.donationLevels.donationLevel;
  const selectedId = donationLevels.length > 0 ? donationLevels[1].level_id : 0;
  const otherAmount = document.getElementById('other_amount');

  //let hasEventDetails = false;

  /** Functions */
  const closeModals = () => {
    setEditGoalModalIsOpen(false);
    setEditTitleModalIsOpen(false);
    setEditPhotoModalIsOpen(false);
    setEditBackgroundPhotoModalIsOpen(false);
    setEditRichTextModalIsOpen(false);
    setEditEventModalIsOpen(false);
    setMobileSocialShareModalIsOpen(false);
    setErrorMessage(null);
  };

  const renderHeroImage = () => {
    const image = luminateProperties.personalPage.personalEvent.backgroundImage;
    const bgImg = image && image !== 'User Provided No Response' ? luminateProperties.personalPage.personalEvent.backgroundImage : DEFAULT_BG_IMAGE;

    return (
      <div className="hero has-page-covering" style={{ backgroundImage: `url('${bgImg}')` }}>
        <div className="has-text-right" data-container="background-image">
          {renderEditButton({
            text: 'Edit Photo',
            action: () => {
              setEditBackgroundPhotoModalIsOpen(true);
              setSelectedBackgroundImage(bgImg);
            },
          })}
        </div>
      </div>
    );
  };

  const renderDonationPills = () => (
    <DonationPills
      donationLevels={donationLevels}
      clickHandler={handleSetDonationAmount}
      selectedId={selectedId}
      sectionTopText={`Support ${USER_FIRST_NAME} with a  donation`}
    />
  );

  const renderDonationButton = () => {
    if (donationLevels && !donationAmount) {
      // Set default selected
      donationLevels.map(level => {
        if (level.level_id === selectedId) {
          const level_id = level.level_id;
          const amount = level.amount.decimal.split('.')[0];
          handleSetDonationAmount({ level_id, amount });
        }
      });
    }

    return (
      <a
        href={(isOtherAmount) ? `${DONATION_LINK}&set.Value=${donationAmount * 100}&set.DonationLevel=${donationLevelId}` : `${DONATION_LINK}&set.DonationLevel=${donationLevelId}`}
        target="_blank"
        rel="noreferrer"
        className="button is-donate is-pulled-right donate"
      >
        Donate ${donationAmount ? donationAmount : '0'}
      </a>
    );
  };

  const renderProgressBar = () => {
    if (navigator.userAgent.includes('Firefox')) {
      const bodyTag = document.getElementsByTagName('body')[0];
      bodyTag.classList.add('is-firefox');
    }
    return (
      <ProgressBar
        goal={personalProgress.data.goal}
        progress={personalProgress.data.raised}
        showIndicator
        goalText="Goal:"
        raisedText="Amount Raised:"
        editButton={renderEditButton({ text: 'Edit Goal', action: () => setEditGoalModalIsOpen(true), className: 'edit-goal' })}
      />
    );
  };

  const renderTopDonors = () => {
    const donors = document.getElementsByClassName('team-honor-list-row');
    const numDonations = document.querySelectorAll('.team-honor-list-name');

    const items = [];

    if (numDonations.length > 0) {
      for (const donor of donors) {
        let donorName = donor.querySelector('.team-honor-list-name').innerHTML.trim();
        donorName = donorName.replace('&amp;', `&`);
        const donorAmount = donor.querySelector('.team-honor-list-value').innerHTML.trim();
        if (donorName && donorAmount) {
          items.push({
            title: `${donorName}`,
            subtitle: donorAmount,
          });
        }
      }
      if (items.length > 0) {
        return <TopList {...{ items }} />;
      }
    } else {
      return <p>No donations yet.</p>;
    }
  };

  const renderTitle = () => {
    const title = screenName.data ? screenName.data : USER_FULL_NAME;
    if (title) {
      return (
        <PageTitle
          title={title}
          donationLink={`${DONATION_LINK}`}
          editButton={renderEditButton({ text: 'Edit Title', action: () => setEditTitleModalIsOpen(true), className: 'edit-title' })}
        />
      );
    }
  };

  const renderEventNameLink = () => {
    if (TEAM_NAME) {
      return (
        <div className="event-link">
          <a href={TEAM_URL}>{TEAM_NAME}</a>
        </div>
      );
    }
  };

  const renderProfileImage = () => <ProfileImage src={personalPhoto.data.customUrl} />;

  const renderSocialShare = () => <SocialShare title={'Share'} description={'Help support the kids of St. Jude'} image={'../images/events/socialevents.jpg'} />;

  const renderMobileSocialShareButton = ({ action }) => <button onClick={action}>Share</button>;

  const renderMobileSocialShareModal = () => (
    <MobileSocialShareModal isOpen={mobileSocialShareModalIsOpen} onClose={closeModals} socialShare={renderSocialShare()} />
  );

  const renderChangeGoalModal = () => (
    <EditGoalModal
      isOpen={editGoalModalIsOpen}
      onClose={closeModals}
      goal={convertCentsToDollars(personalProgress.data.goal)}
      handleSubmit={handleUpdateGoal}
      errorMessage={errorMessage}
    />
  );

  const renderChangeTitleModal = () => (
    <EditTitleModal
      isOpen={editTitleModalIsOpen}
      onClose={closeModals}
      title={screenName.data || USER_FULL_NAME}
      handleSubmit={handleUpdateTitle}
      errorMessage={errorMessage}
    />
  );

  const renderChangePhotoModal = () => (
    <EditImageModal
      isOpen={editPhotoModalIsOpen}
      onClose={closeModals}
      image={personalPhoto.data.customUrl}
      isLoading={isLoading}
      handleSubmit={handleUpdatePhoto}
      errorMessage={errorMessage}
    />
  );

  const renderChangeBackgroundPhotoModal = () => {
    if (bgImageData.data && bgImageData.data.images) {
      const imgData = bgImageData.data.images;
      if (imgData.length > 0) {
        return (
          <EditBackgroundImageModal
            isOpen={editBackgroundPhotoModalIsOpen}
            onClose={closeModals}
            image={selectedBackgroundImage}
            isLoading={isLoading}
            handleSubmit={handleUpdateBackgroundPhoto}
            errorMessage={errorMessage}
            bgImageData={imgData}
          />
        );
      }
    }
  };

  const renderChangeRichTextModal = () => (
    <EditRichTextModal
      isOpen={editRichTextModalIsOpen}
      onClose={closeModals}
      richText={personalPageInfo.data.richText}
      handleSubmit={handleUpdateRichText}
      errorMessage={errorMessage}
    />
  );

  const renderChangeEventModal = () => {
    if (surveyResponses.data.surveyResponses && IS_OWN_PAGE) {
      const eventDetailsResponses = [];
      surveyResponses.data.surveyResponses.forEach((response, i) => {
        if (i < 11) {
          eventDetailsResponses.push({
            key: response.key,
            value: typeof response.responseValue !== 'object' ? response.responseValue : '',
            questionText: response.questionText,
            type: response.questionType,
          });
        }
      });

      return (
        <EditEventDetailsModal
          isOpen={editEventModalIsOpen}
          onClose={closeModals}
          surveyData={eventDetailsResponses}
          handleSubmit={handleUpdateEventDetails}
          isLoading={isLoading}
          errorMessage={errorMessage}
        />
      );
    }
  };

  const renderEditButton = ({ text, action, className }) => {
    if (IS_OWN_PAGE) {
      return (
        <div className={`edit-container ${className ? className : ''}`} data-container="edit-container">
          <button className="button is-link is-small is-narrow" onClick={action}>
            <span className="icon is-small">
              <i className="svg-icon edit-icon is-white"></i>
            </span>
            <span>{text}</span>
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const renderEventDetails = () => {
    const eventDetails = [];
    const personalEvent = IS_OWN_PAGE ? {} : luminateProperties.personalPage.personalEvent;
    const userResponses = surveyResponses.data.surveyResponses;

    if (IS_OWN_PAGE) {
      if (userResponses && userResponses.length > 0) {
        userResponses.map(response => {
          const value = typeof response.responseValue === 'object' ? '' : response.responseValue;
          switch (response.key) {
            case 'EVENT_VENUE':
              personalEvent.venue = value;
              break;
            case 'EVENT_STREET_ADDRESS1':
              personalEvent.address1 = value;
              break;
            case 'EVENT_STREET_ADDRESS2':
              personalEvent.address2 = value;
              break;
            case 'EVENT_DATE':
              personalEvent.startDate = value;
              break;
            case 'EVENT_END_DATE':
              personalEvent.endDate = value;
              break;
            case 'EVENT_START_TIME':
              personalEvent.startTime = value;
              break;
            case 'diy_event_end_time':
              personalEvent.endTime = value;
              break;
            case 'EVENT_CITY':
              personalEvent.city = value;
              break;
            case 'EVENT_STATE':
              personalEvent.state = value;
              break;
            case 'EVENT_ZIP':
              personalEvent.postalCode = value;
              break;
          }
        });
      }
    }
    if (
      !personalEvent.venue &&
      !personalEvent.address1 &&
      !personalEvent.address2 &&
      !personalEvent.startDate &&
      !personalEvent.endDate &&
      !personalEvent.startTime &&
      !personalEvent.endTime
    ) {
      //hasEventDetails = false;
      return false;
    }

    //hasEventDetails = true;

    Object.keys(personalEvent).forEach(data => {
      personalEvent[data] = personalEvent[data] && personalEvent[data].includes('User Provided No Response') ? '' : personalEvent[data];
    });

    const setStartEndInfo = (start, end) => `${start} ${end ? ' - ' + end : ''}`;
    const setActionInfo = (icon, subText, link) => ({ icon, subText, link });
    const setValueOrNone = value => (value ? value : '');
    const setEventGeo = event => `${event.city ? event.city + ', ' : ''} ${event.state ? event.state : ''} ${event.postalCode ? event.postalCode : ''}`;
    const setEventDetails = (array, textRows, actionButton) => {
      array.push({ textRows, actionButton });
    };
    const getGoogleMapLinkFromAddress = event => {
      let address = `${event.venue || ''}`;
      address += `${event.address1 || ''} ${event.address2 || ''}`;
      address += ` ${event.city || ''} ${event.state ? ', ' + event.state : ''} ${event.postalCode || ''}`;
      return `https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`;
    };

    const eventDetailsContent = [
      {
        text: [setStartEndInfo(personalEvent.startDate, personalEvent.endDate), setStartEndInfo(personalEvent.startTime, personalEvent.endTime)],
        action: setActionInfo('calendar', '', 'no-link'),
      },
      {
        text: [setValueOrNone(personalEvent.venue), setValueOrNone(personalEvent.address1), setValueOrNone(personalEvent.address2), setEventGeo(personalEvent)],
        action: setActionInfo('marker', 'MAP', getGoogleMapLinkFromAddress(personalEvent)),
      },
    ];

    eventDetailsContent.map(row => setEventDetails(eventDetails, row.text, row.action));

    return (
      <div data-container="event-details">
        <h3 className="has-text-white">
          Event Details
          {renderEditButton({
            text: 'Edit Event',
            action: () => setEditEventModalIsOpen(true),
          })}
        </h3>
        <EventDetailsRow eventDetails={eventDetails} />
      </div>
    );
  };

  const getAchievementLayout = (className, text) => (
    <div className="achievement">
      <div className="achievement-badge">
        <i className={className}></i>
      </div>
      <span>{ReactHtmlParser(text)}</span>
    </div>
  );
  const renderAchievements = () => {
    const achievements = participantAchievements.data.achievements;
    if (achievements) {
      if (
        achievements.facebook_fundraiser === 'FALSE' &&
        achievements.updated_page === 'FALSE' &&
        achievements.donations_1 === 'FALSE' &&
        achievements.donations_5 === 'FALSE' &&
        achievements.is_self_donor === 'FALSE' &&
        achievements.percent_50 === 'FALSE' &&
        achievements.percent_100 === 'FALSE'
      ) {
        return <p>No achievements to display.</p>;
      } else {
        return (
          <div className="achievements">
            {achievements.facebook_fundraiser === 'TRUE' && getAchievementLayout('achievement-facebook', '<p>Facebook<br>Fundraiser</p>')}
            {achievements.updated_page === 'TRUE' && getAchievementLayout('achievement-storyteller', '<p>St. Jude<br>Storyteller</p>')}
            {achievements.donations_1 === 'TRUE' && getAchievementLayout('achievement-donations-first', '<p>First<br>Donation!</p>')}
            {achievements.donations_5 === 'TRUE' && getAchievementLayout('achievement-donations-five', '<p>5+<br>Donations</p>')}
            {achievements.is_self_donor === 'TRUE' && getAchievementLayout('achievement-kickstart', '<p>Kickstart<br>Donor</p>')}
            {achievements.percent_50 === 'TRUE' && getAchievementLayout('achievement-halfway', '<p>Halfway<br>to Goal</p>')}
            {achievements.percent_100 === 'TRUE' && getAchievementLayout('achievement-goal-met', '<p>Goal Met!</p>')}
          </div>
        );
      }
    } else {
      return <p>No achievements to display.</p>;
    }
  };

  const renderSidebar = () => (
    <div>
      <div className="dark-gradient-bg">
        {renderEventDetails()}
        <div data-container="achievements">
          <h3 className="has-text-white">{USER_FIRST_NAME}&apos;s Achievements</h3>
          {renderAchievements()}
        </div>
        <div data-tab-content="donors">
          <h3 className="has-text-white">{USER_FIRST_NAME}&apos;s Donors</h3>
          <div data-container="donors">{renderTopDonors()}</div>
        </div>
      </div>
    </div>
  );

  const handleUpdateGoal = async values => {
    const response = await updatePersonalGoal({ eventId: EVENT_ID, goal: values.goal * 100 });
    if (response.success) {
      personalProgress.reload();
      setEditGoalModalIsOpen(false);
    } else {
      setErrorMessage(response.message);
    }
  };

  const handleUpdatePhoto = async blob => {
    setIsLoading(true);
    const response = await updatePersonalPhotos({ eventId: EVENT_ID, photo: blob });
    setIsLoading(false);
    if (response.success) {
      personalPhoto.reload();
      setEditPhotoModalIsOpen(false);
    } else {
      setErrorMessage(response.message);
    }
  };

  const handleUpdateBackgroundPhoto = async bgImage => {
    setIsLoading(true);
    const responses = [
      {
        type: 'string',
        key: 'question_key_PERSONAL_BACKGROUND',
        value: bgImage,
      },
    ];
    const response = await updateSurveyBackgroundResponses(EVENT_ID, responses);
    setIsLoading(false);

    if (response[0].success) {
      surveyResponses.reload();
      setEditBackgroundPhotoModalIsOpen(false);
      luminateProperties.personalPage.personalEvent.backgroundImage = bgImage;
    } else {
      setErrorMessage(response[0].message);
    }
  };

  const handleUpdateRichText = async values => {
    const response = await updatePersonalPage({ eventId: EVENT_ID, richText: values.richText });
    if (response.success) {
      personalPageInfo.reload();
      setEditRichTextModalIsOpen(false);
    } else {
      setErrorMessage(response.message);
    }
  };

  const handleUpdateTitle = async values => {
    const response = await updateScreenName({ eventId: EVENT_ID, screenName: values.title });
    if (response.success) {
      screenName.reload();
      setEditTitleModalIsOpen(false);
    } else {
      setErrorMessage(response.message);
    }
  };

  const handleSetDonationAmount = ({ level_id, amount }) => {
    setDonationLevelId(level_id);
    setDonationAmount(amount);
    return otherAmount && setIsOtherAmount(true);
  };

  const handleUpdateEventDetails = async (values, surveyData) => {
    setIsLoading(true);
    const responses = [];
    surveyData.map(data => {
      Object.keys(values).map(value => {
        console.log(data.key, values[value]);
        if (data.key === value) {
          responses.push({
            key: `question_key_${data.key}`,
            type: data.type,
            value: values[value],
          });
        }
      });
    });
    const response = await updateSurveyResponses(EVENT_ID, responses);
    setIsLoading(false);

    if (response[0].success) {
      await surveyResponses.reload();
      setEditEventModalIsOpen(false);
    } else {
      setErrorMessage(response[0].message);
    }
  };

  return (
    <div className="diy-personal">
      {renderChangeGoalModal()}
      {renderChangeTitleModal()}
      {renderChangePhotoModal()}
      {renderChangeBackgroundPhotoModal()}
      {renderChangeRichTextModal()}
      {renderChangeEventModal()}
      {renderMobileSocialShareModal()}

      {renderHeroImage()}
      <section className="section">
        <div className="container">
          <div className="columns">
            {/* left column */}
            <div className="column is-one-quarter-desktop sidebar-section is-hidden-mobile is-hidden-tablet-only">
              <div className="has-text-right" data-container="profile-image">
                {renderEditButton({
                  text: 'Edit Photo',
                  action: () => setEditPhotoModalIsOpen(true),
                })}
                {renderProfileImage()}
              </div>
              {renderSidebar()}
            </div>

            {/* right column */}
            <div className="column is-three-quarter-desktop main-content-section">
              <div className="has-text-right is-hidden-desktop" data-container="profile-image">
                <div className="is-hidden-desktop mobile-social-share-button">
                  {renderMobileSocialShareButton({
                    action: () => setMobileSocialShareModalIsOpen(true),
                  })}
                </div>
                {renderEditButton({
                  text: 'Edit Photo',
                  action: () => setEditPhotoModalIsOpen(true),
                })}
                {renderProfileImage()}
              </div>
              <div className="title-and-progress-container">
                <div data-container="title">
                  <div className="level">
                    <div className="level-left">
                      {renderTitle()}
                      {renderEventNameLink()}
                    </div>
                    <div className="level-right is-hidden-mobile is-hidden-tablet-only">
                      <div data-container="social-share" className="columns is-vcentered">
                        <p className="has-text-weight-bold column is-narrow">Share:</p>
                        {renderSocialShare()}
                      </div>
                    </div>
                  </div>
                </div>
                <div data-container="progress">
                  {renderProgressBar()}
                  {renderDonationPills()}
                  {renderDonationButton()}
                </div>
              </div>
              <div data-container="about">
                {renderEditButton({
                  text: 'Edit Story',
                  action: () => setEditRichTextModalIsOpen(true),
                })}
                {ReactHtmlParser(personalPageInfo.data.richText)}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="is-hidden-desktop">{renderSidebar()}</div>
    </div>
  );
};
